<template>
  <div>
    <div class="_BoxHeader">
      <span @click="beforeunloadFn()">题库导入</span>
    </div>
    <div class="PageContain" v-loading="loading">
      <div class="_SelectHeader">
        <span class="_Title">列表信息</span>
        <div v-show="!isNotPurview">
          <el-button type="primary" @click="uploadFile">导入</el-button>
        </div>
      </div>
      <input
        type="file"
        @change="importFile(this)"
        id="imFile"
        ref="fileBtn"
        style="display: none"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      />
      <div class="contain-box" :style="{ height: elementHeight + 'px' }">
        <div>
          <div class="letter">
            <span
              v-for="(item, index) in letter"
              :key="index"
              :class="[index < 22 ? 'width1' : 'width2']"
              class="item"
              >{{ item }}</span
            >
          </div>
          <el-table
            :data="tableList"
            :height="elementHeight"
            style="width: 100%"
            border
            id="table"
            :span-method="objectSpanMethod"
          >
            <el-table-column prop="CompName" label="公司" width="120">
            </el-table-column>
            <el-table-column prop="DeptName" label="部门" width="120">
            </el-table-column>
            <el-table-column prop="PostName" label="岗位" width="120">
            </el-table-column>
            <el-table-column prop="FunctionName" label="职能" width="120">
            </el-table-column>
            <el-table-column prop="FileNo" label="文件编号" width="120">
            </el-table-column>
            <el-table-column prop="FileName" label="文件名称" width="120">
            </el-table-column>
            <el-table-column prop="Question" label="检查内容" width="120">
            </el-table-column>
            <el-table-column prop="AuditMethod" label="审核方式" width="120">
            </el-table-column>
            <el-table-column prop="Answer" label="标准要求" width="120">
            </el-table-column>
            <el-table-column
              prop="ExamQuestion"
              label="判断题（答案是）"
              width="120"
            >
            </el-table-column>
            <el-table-column
              prop="DisturbQuestion"
              label="判断题（答案否）"
              width="120"
            >
            </el-table-column>
            <el-table-column prop="ChoiceQue" label="选择题" width="120">
            </el-table-column>
            <el-table-column
              prop="ChoiceQueOption"
              label="选择题答案"
              width="120"
            >
            </el-table-column>
            <el-table-column
              prop="ChoiceQueAnswer"
              label="选择题正确答案"
              width="120"
            >
            </el-table-column>
            <el-table-column prop="Interpretation" label="应知应会" width="120">
            </el-table-column>

            <el-table-column prop="RiskPoints" label="风险点" width="120">
            </el-table-column>
            <el-table-column
              prop="RiskMeasures"
              label="风险应对措施"
              width="120"
            >
            </el-table-column>
            <el-table-column prop="Version" label="版本号" width="120">
            </el-table-column>
            <el-table-column prop="Module" label="检查模块" width="120">
            </el-table-column>
            <el-table-column prop="IsCurrency" label="通用" width="120">
            </el-table-column>
            <el-table-column prop="IsFuncCurrency" label="职能通用" width="120">
            </el-table-column>
            <el-table-column prop="IsSpecial" label="专指" width="120">
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <Dialog
      title="查看错误"
      :showDialog="showErrorDialog"
      width="80%"
      showTowText="true"
      @handleClose="handleClose"
      @handleReqData="handleClose"
      top="1vh"
      class="error-dialog"
    >
      <el-table
        :data="tableList2"
        :height="elementHeight"
        style="width: 100%"
        border
        id="table"
      >
        <el-table-column prop="fileNo" label="文件名" width="320">
        </el-table-column>
        <el-table-column prop="saveTime" label="导入时间" width="150">
          <template slot-scope="scope">
            <span>{{ scope.row.saveTime.split('T')[0] }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="errorLog" label="错误信息" show-overflow-tooltip>
          <template slot-scope="scope">
            <span @click="clickText(scope.row.errorLog)">{{
              scope.row.errorLog
            }}</span>
          </template>
        </el-table-column>
      </el-table>
    </Dialog>
    <Dialog
      title="错误信息"
      :showDialog="showErrorText"
      width="55%"
      showTowText="true"
      @handleClose="showErrorText = false"
      @handleReqData="showErrorText = false"
      top="5vh"
      class="error-dialog"
    >
      <div class="error-text">
        <span>{{ errorText }}</span>
      </div>
    </Dialog>
  </div>
</template>

<script>
import XLSX from 'xlsx'
import { get, post } from '../../api/http'
import Dialog from '../../components/dialog/Dialog.vue'
import { objectSpanMethod } from '../../modules/TableConflate'

export default {
  components: { Dialog },
  data() {
    return {
      isNotPurview: false,
      elementHeight: 0,
      loading: false,
      tableList: [],
      showErrorDialog: false,
      tableList2: [],
      errorText: '',
      showErrorText: false,
      letter: [
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'I',
        'J',
        'K',
        'L',
        'M',
        'N',
        'O',
        'P',
        'Q',
        'R',
        'S',
        'T',
        'U',
        'V',
      ],
      reqList: [],
      propertyList: [],
      dataList: [],
      run: true,
    }
  },

  mounted() {
    this.imFile = document.getElementById('imFile')
    this.getElementHeight()
    // 普通角色 不能导入题库，其它权限正常
    var roleName = JSON.parse(sessionStorage.getItem('userInfo')).roleName.split(',') 
    if (
      roleName.indexOf('股份公司管理员') == -1 &&
      roleName.indexOf('公司管理员') == -1 &&
      roleName.indexOf('部门管理员') == -1
    ) {
      this.isNotPurview = true
    }
  },
  methods: {
    getElementHeight() {
      this.$nextTick(() => {
        this.elementHeight =
          window.innerHeight -
          (document.getElementById('table').offsetTop + 60 + 100)
      })
    },
    objectSpanMethod({ rowIndex, columnIndex }) {
      return objectSpanMethod(rowIndex, columnIndex, this.tableList)
    },
    // 导入excel
    uploadFile: function () {
      // 点击导入按钮
      this.imFile.click()
    },
    importFile: function () {
      let obj = this.imFile
      if (!obj.files) {
        return
      }
      // this.loading = true
      this.tableList = []
      var f = obj.files[0]

      var reader = new FileReader()
      let $this = this
      reader.onload = function (e) {
        var data = e.target.result
        if ($this.rABS) {
          $this.wb = XLSX.read(btoa(this.fixdata(data)), {
            // 手动转化
            type: 'base64',
          })
        } else {
          $this.wb = XLSX.read(data, {
            type: 'binary',
          })
        }
        var excelData = ''
        $this.wb.SheetNames.forEach(i =>{
           excelData = XLSX.utils.sheet_to_json(
            // $this.wb.Sheets[$this.wb.SheetNames[0]]
            $this.wb.Sheets[i]
          )
        
        console.log(excelData)
        var stopExecution = false
        excelData.map((item, index) => {
          if (stopExecution) return
          // 第一和第二行为标题的情况下不导入
          if (
            (index == 0 || index == 1) &&
            !Object.prototype.hasOwnProperty.call(item, '公司')
          ) {
            return
          }
          if (
            //从exal表第一行开始必须要有公司
            !Object.prototype.hasOwnProperty.call(item, '公司') &&
            index == 2
          ) {
            return (stopExecution = true)
          }
          // 处理合并的数据(现状：学习多行与题库一行合并)
          if (Object.prototype.hasOwnProperty.call(item, '标准答案')) {
            $this.$message({
              type: 'warning',
              message: '第' + (index - 1) + '行标准要求为标准答案，字段错误',
            })
            return (stopExecution = true)
          }
          if (!Object.prototype.hasOwnProperty.call(item, '公司')) {
            item.公司 = excelData[index - 1].公司
          }
          if (!Object.prototype.hasOwnProperty.call(item, '部门')) {
            item.部门 = excelData[index - 1].部门
          }
          if (!Object.prototype.hasOwnProperty.call(item, '岗位')) {
            item.岗位 = excelData[index - 1].岗位
          }
          if (!Object.prototype.hasOwnProperty.call(item, '职能')) {
            item.职能 = excelData[index - 1].职能
          }
          if (!Object.prototype.hasOwnProperty.call(item, '文件编号')) {
            item.文件编号 = excelData[index - 1].文件编号
          }
          if (!Object.prototype.hasOwnProperty.call(item, '文件名称')) {
            item.文件名称 = excelData[index - 1].文件名称
          }
          if (!Object.prototype.hasOwnProperty.call(item, '应知应会')) {
            item.应知应会 = excelData[index - 1].应知应会
          }
          if (
            Object.prototype.hasOwnProperty.call(item, '标准要求') &&
            !Object.prototype.hasOwnProperty.call(item, '检查内容')
          ) {
            //！问题和要求合并后第二行为空时不需要赋值
            //！问题和要求为空或有值都需要同时存在
            //改代码处理要求没合并，问题合并
            item.检查内容 = excelData[index - 1].检查内容
          }
          if (
            !Object.prototype.hasOwnProperty.call(item, '标准要求') &&
            Object.prototype.hasOwnProperty.call(item, '检查内容')
          ) {
            item.标准要求 = excelData[index - 1].标准要求
          }
          var data = {
            CompName: item.公司,
            DeptName: item.部门,
            PostName: item.岗位,
            FunctionName: item.职能,
            FileNo: item.文件编号,
            FileName: item.文件名称,
            Question: item.检查内容,
            AuditMethod: item.审核方式, //审核方式
            Answer: item.标准要求,
            Version: item.版本号,
            RiskPoints: item.风险点,
            RiskMeasures: item.风险应对措施,
            Module: item.检查模块,
            IsCurrency: item.通用 ? '√' : '',
            IsFuncCurrency: item.职能通用 ? '√' : '',
            IsSpecial: item.专指 ? '√' : '',
            AreaAudit: item.检查类别 ? '√' : '', //现场检查
            FileAudit: item.__EMPTY ? '√' : '', //文件检查
            ExamQuestion: item['判断题（答案是）'],
            DisturbQuestion: item['判断题（答案否）'],
            Interpretation: item.应知应会,
            ChoiceQue: item.选择题,
            ChoiceQueOption: item.选择题答案,
            ChoiceQueAnswer: item.选择题正确答案,
          }
          $this.tableList.push(data)
        })
      })
        console.log('tableList=', $this.tableList)
        if ($this.tableList.length != 0) {
          $this.importList(obj.files[0].name.split('.')[0])
        } else {
          console.log('格式不符合的数据=', excelData)
          $this.$message({
            type: 'warning',
            message: '导入文件不符合',
          })
          $this.loading = false
          $this.$refs.fileBtn.value = null;
        }
      }
      if (this.rABS) {
        reader.readAsArrayBuffer(f)
      } else {
        reader.readAsBinaryString(f)
      }
    },

    importList(excelName) {
      this.dataList = []
      this.tableList.map((item) => {
        this.dataList.push({
          CompName: item.CompName,
          DeptName: item.DeptName,
          PostName: item.PostName,
          FunctionName: item.FunctionName,
          FileNo: item.FileNo,
          FileName: item.FileName ? item.FileName : '',
          Question: item.Question ? item.Question : '',
          AuditMethod: item.AuditMethod ? item.AuditMethod : '',
          Answer: (item.Answer ? item.Answer : '') + '',
          Version: item.Version ? item.Version : '',
          RiskPoints: item.RiskPoints ? item.RiskPoints : '',
          RiskMeasures: item.RiskMeasures ? item.RiskMeasures : '',
          Module: item.Module ? item.Module : '',
          IsCurrency: item.IsCurrency ? 1 : 0,
          IsFuncCurrency: item.IsFuncCurrency ? 1 : 0,
          IsSpecial: item.IsSpecial ? 1 : 0,
          AreaAudit: item.AreaAudit ? 1 : 0,
          FileAudit: item.FileAudit ? 1 : 0,
          arrArea: '',
          arrAuditType: '',
          ExamQuestion: item.ExamQuestion ? item.ExamQuestion : '',
          DisturbQuestion: item.DisturbQuestion ? item.DisturbQuestion : '',
          Interpretation: item.Interpretation,
          ChoiceQue: item.ChoiceQue ? item.ChoiceQue : '',
          ChoiceQueOption: item.ChoiceQueOption ? item.ChoiceQueOption : '',
          ChoiceQueAnswer: item.ChoiceQueAnswer ? item.ChoiceQueAnswer : '',
        })
      })
      this.postBatchAdd(this.dataList, excelName)
    },
    async postBatchAdd(list, excelName) {
      await post('/api/QuesPoolAndExam/CheckData?ExcelName=' + excelName, {
        data: JSON.stringify(list),
      })
        .then((resp) => {
          if (resp.code == 200) {
            this.$message({
              type: 'success',
              message: '验证成功,开始导入',
            })
            this.run = true
            this.dataList.map((i, index) => {
              if (!this.run) return
              this.importData(i, index)
            })
          } else {
            this.showError(resp.message)
          }
          this.$refs.fileBtn.value = null;
        })
        .catch(() => {
          this.loading = false
          this.$message.error('系统异常,请稍后再试')
        })
    },
    async importData(i, index) {
      await post('/api/QuesPoolAndExam/BatchAdd', i)
        .then((resp) => {
          if (resp.code != 200) {
            this.run = false
            console.log(
              '1)第' + (index + 1) + '行导入失败',
              '2)错误行数：',
              i,
              '3)错误原因：' + resp.message
            )
            this.$message({
              type: 'error',
              message:
                ('1)第' + index + 1 + '行导入失败',
                '2)错误行数：' + i,
                '3)错误原因：' + resp.message),
            })
            this.showError(resp.message)
            return
          }
          if (index + 1 == this.dataList.length) {
            this.loading = false
            this.$message({
              type: 'success',
              message: '已全部导入',
            })
          }
        })
        .catch(() => {
          this.run = false
          this.loading = false
          this.$message.error('系统异常,请稍后再试')
        })
    },
    showError(msg) {
      this.$confirm(msg, {
        confirmButtonText: '查看错误',
        cancelButtonText: '关闭',
        type: 'error',
      })
        .then(() => {
          get(
            '/api/QuesErrorlog/GetByUserId?UserId=' +
              JSON.parse(sessionStorage.getItem('userInfo')).id
          ).then((resp) => {
            if (resp.code == 200) {
              this.tableList2 = resp.data
            }
          })
          this.showErrorDialog = true
        })
        .catch(() => {
          // location.reload()
          this.tableList = [];
        })
    },
    handleClose() {
      this.showErrorDialog = false
      // location.reload()
      this.tableList = [];
    },
    clickText(e) {
      this.errorText = e
      this.showErrorText = true
    },
  },
}
</script>

<style lang="less" scoped>
._SelectHeader {
  width: 99%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  > div {
    padding-right: 20px;
  }
}
/deep/ .el-table .cell {
  line-height: 15px !important;
}
.contain-box {
  position: absolute;
  top: 50px;
  bottom: 0;
  overflow-y: hidden;
  overflow-x: auto;
  background-color: #e8e9ee;
  width: 100%;
  > div {
    overflow: auto;
    border: 1px solid #dbdbdb;
    display: inline-block;
    padding-right: 20px;
  }
}
.letter {
  display: flex;
  flex-wrap: nowrap;
}
.letter .item {
  text-align: center;
  display: inline-block;
  border-right: 1px solid #dbdbdb;
  background-color: white;
  padding: 2px 0;
  border-bottom: 1px solid #dbdbdb;
}
.width1 {
  width: 119.2px;
}
.width2 {
  width: 39.2px;
}
/deep/ .el-dialog__wrapper {
  overflow: hidden !important;
}
.error-text {
  width: 100%;
  height: 400px;
  overflow: scroll;
}
</style>
